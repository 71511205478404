import { Box, Center, Link, VStack } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import DepositPlan from "@/components/DepositPlan";
import TagManager from "react-gtm-module";
import useAPI from "@/libs/hooks/api";
import { useOutletContext } from "react-router-dom";
import OrderConfirm from "@/components/OrderConfirm";
import { useTranslation } from "react-i18next";
import AuthContext from "@/context/auth";
import useCrisp from "@/libs/hooks/crisp";

const DepositPage = () => {
  const { t } = useTranslation();
  const api = useAPI();
  const crisp = useCrisp();
  const { user } = useContext(AuthContext);
  const {
    currency,
    exchangeRates,
    payment,
    setPayment,
    confirm,
    onConfirm,
    onCancel,
  } = useOutletContext();
  const [pricing, setPricing] = useState([]);
  const amount = payment?.points || 0;

  const vip = !!user?.isVip;

  const selectPlan = useCallback(
    (plan) => () => {
      setPayment({ ...plan, type: "deposit" });
      const amount = plan?.cost - plan?.discount;
      TagManager.dataLayer({
        dataLayer: {
          event: `InitiateCheckout_${amount}`,
        },
      });
    },
    [setPayment],
  );

  const otcRequest = useCallback(() => {
    crisp.send("text", "大額儲值");
    crisp.open();
  }, [crisp]);

  useEffect(() => {
    api.getPricing(vip).then(setPricing);
  }, [api, vip]);

  return (
    <Box>
      {!!payment || (
        <VStack color="white" align="stretch" gap={2}>
          {pricing.map((plan) => (
            <DepositPlan
              key={plan.cost}
              onSelect={selectPlan(plan)}
              rate={currency === "TWD" ? 1 : exchangeRates[currency]}
              {...plan}
            />
          ))}
        </VStack>
      )}
      <Center mt={4}>
        <Link
          color="secondary.100"
          textDecoration="underline"
          onClick={otcRequest}
        >
          另有大額儲值服務，請洽客服人員，立即詢問享優惠！
        </Link>
      </Center>

      <OrderConfirm
        isOpen={confirm}
        onClose={onCancel}
        entry={t("payment.profile.purchase", {
          currency: t("common.currency"),
        })}
        amount={amount}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Box>
  );
};

export default DepositPage;
